<template>
    <div class="menu" id="menuManagent">
        <div class="menu_title">
            <div class="el-icon-s-flag descrip">&nbsp;菜单说明：</div>
            <div class="descrip_content">
                <p>1、默认建议菜单应该有2级，功能挂在2级菜单上，权限挂在功能上；</p>
                <p>2、调整任意菜单/功能/权限后，所有包含此菜单/功能/权限的员工都需要重新登里。</p>
            </div>
            
        </div>

        <div class="menu_content">
            <div class="menu_content_title">
                <h4>平台端</h4>
            </div>

            <div class="menu_content_content">
                <div class="menu_left">
                    <el-input placeholder="搜索菜单名称" v-model="filterText" suffix-icon="el-icon-search" style="height: 32px; margin-bottom: 20px"></el-input>

                    <el-tree
                      class="filter-tree"
                      v-if="treeData.length"
                      :data="treeData"
                      :check-strictly="true" 
                      :highlight-current="true"
                      :props="defaultProps"
                      default-expand-all
                      :filter-node-method="filterNode"
                      @node-click="chooseNode"
                      ref="tree">
                      <span class="custom-tree-node" style="font-size: 14px" slot-scope="{node,data}">
                        <span class="el-icon-folder-opened" style="margin-right: 4px"></span><span>{{data.moduleName}}</span>

                      </span>
                    </el-tree>
                </div>

                <div class="menu_right">
                    <el-button type="primary" style="height: 32px; padding: 0 12px" @click="add">新增菜单</el-button>
                
                    <h6 v-if="isShow"><span :class="chooseForm.moduleIcon" style="margin-right: 4px"></span>{{chooseForm.moduleName}} <a @click="editMenu(chooseForm)">编辑</a>
                    
                    <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    @confirm="delMenus(chooseForm)"
                                    title="是否确认删除菜单?"
                                    >
                                    <a slot="reference">删除</a>
                                </el-popconfirm>
                    </h6>

                    <p v-if="isShow"> <span>菜单编码：{{chooseForm.moduleCode}}</span>  <span>序列号：{{chooseForm.moduleSort}}</span> <span>子菜单数量：{{tableData.length}}</span> </p>

                    <div style="margin-top: 4px">
                        <el-table border :data="tableData">
                            <!-- <el-table-column
                                type="selection"
                                width="55">
                            </el-table-column> -->
                            <el-table-column prop="moduleName" label="菜单名称"></el-table-column>
                            <el-table-column prop="moduleCode" label="菜单编号"></el-table-column>
                            <el-table-column prop="moduleUrl" label="菜单路由"></el-table-column>
                            <el-table-column prop="moduleParentName" label="父级菜单名称"></el-table-column>
                            <el-table-column prop="moduleSort" label="序列号"></el-table-column>
                            <!-- <el-table-column prop="name" label="菜单icon">
                              <template slot-scope="scope">
                                <span :class="scope.row.moduleIcon"></span>
                              </template>
                            </el-table-column> -->
                            <el-table-column border-column prop="userBO.userStatus" align="center" label="菜单状态">
                              <template slot-scope="scope">
                                    <el-switch
                                        @change="changeStatus(scope.row)"
                                        v-model="scope.row.moduleStatus == 'status_enabled' ? true : false"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949">
                                    </el-switch>

                                </template>
                            </el-table-column>
                            <el-table-column prop="name" label="操作" align="center">
                                <template slot-scope="scope">
                                    <el-button @click="editMenu(scope.row)" type="text" size="small">编辑</el-button>
                                    <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    @confirm="delMenu(scope.row)"
                                    title="是否确认删除菜单?"
                                    >
                                    <el-button type="text" style="margin-left: 12px" slot="reference" size="small">删除</el-button>
                                </el-popconfirm>
                                    
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>



        <el-dialog :title="dialogTitle" width="40%" :visible.sync="dialogFormVisible">
            <el-form :model="form" ref="cdForm" :rules="rules" style="width: 80%; margin: 20px auto 0;" label-width="80px">
                <el-form-item label="菜单名称" prop="moduleName">
                    <el-input v-model="form.moduleName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="父级">
                    <el-popover
                        placement="bottom"
                        width="414"
                        trigger="click"
                        >
                        <el-tree :data="treeData" v-if="treeData.length" default-expand-all :props="defaultProps" @node-click="handleNodeClick"></el-tree>
                        
                        <el-input readonly="" v-model="form.moduleParentName" autocomplete="off" slot="reference"></el-input>
                    </el-popover>
                </el-form-item>
                <el-form-item label="路由">
                    <el-input v-model="form.moduleUrl" autocomplete="off"></el-input>
                </el-form-item>
            
                <el-form-item label="序列号" prop="moduleSort">
                    <el-input v-model="form.moduleSort" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="菜单icon">
                    <el-popover
                        placement="bottom"
                        width="414"
                        trigger="click"
                        >
                        <div style="padding: 20px; height: 300px; overflow: auto;">
                            <a v-for="(item,index) in json.iconList" @click="chooseIcon(item)" :style="item == form.icon ? {color: '#409EFF'} : ''" :class="item" :key="index" style="font-size: 24px; margin: 0 10px 8px;"></a>                        
                        </div>
                        
                        <el-input v-model="form.moduleIcon" autocomplete="off" slot="reference"></el-input>
                    </el-popover>
                </el-form-item>
                
                <el-form-item label="状态" prop="moduleStatus">
                    <el-radio v-model="form.moduleStatus" label="status_enabled">启用</el-radio>
                    <el-radio v-model="form.moduleStatus" label="status_disabled">停用</el-radio>
                </el-form-item>
                
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: right">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addMenu">确 定</el-button>
            </div>
      </el-dialog>
    </div>
</template>

<script>
// import json from '../../assets/json/icon.json'
export default {
    data() {
      return {
        dialogTitle: '新增菜单',
        dialogFormVisible: false,
        isShow: false,
        json: require('../../assets/json/icon.json'),
        form: {
            moduleParentName: '',
            moduleParentCode: '',
            moduleName: '',
            moduleUrl: '',
            moduleSort: '',
            moduleIcon: '',
            moduleStatus: 'status_enabled',
            moduleType: 2
        },
        rules: {
          moduleName: {required: true, message: '请输入菜单名称', trigger: 'blur'},
          moduleSort: {required: true, message: '请输入序列号', trigger: 'blur'},
        },
        chooseForm: {},
        filterText: '',
        tableData: [],
        treeData: [],
        defaultProps: {
          children: 'children',
          label: 'moduleName'
        }
      };
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
        console.log(val)
      }
    },
    mounted() {
      this.qryMenu();
      if (window.innerHeight){
            var winHeight = window.innerHeight;
        }
        else if ((document.body) && (document.body.clientHeight)){
            var winHeight = document.body.clientHeight;
        }
        document.getElementById("menuManagent").style.minHeight = (winHeight-90)+'px';
    },
    methods: {
      //查询所有菜单
      qryMenu(){
        this.qa.queryMenu({}).then((res => {
          this.treeData = this.FWZX.buildTree(res.data, 'moduleCode', 'moduleParentCode');
          console.log(this.treeData)
        }))
      },
      //新增按钮
      add(){
        this.dialogTitle = "新增菜单";
        Object.keys(this.form).forEach(key => (this.form[key] = ""));
        this.form.moduleStatus = 'status_enabled';
        this.form.moduleType = 2;
        this.dialogFormVisible = true;
      },
      //新增菜单
      addMenu(){
        this.$refs.cdForm.validate((valid) => {
            if (valid) {    
              if(this.dialogTitle == "新增菜单") {
                this.qa.addMenu(this.form).then((res) => {
                  if(res.respCode == "0000") {
                    this.$message({
                        message: '菜单新增成功！',
                        type: 'success'
                    });
                    this.qa.queryPageMenu({moduleParentCode: this.chooseForm.moduleCode, pageNo: 1, pageSize: 100}).then((res) => {
                      if(res.respCode == "0000") {
                        this.tableData = res.data;
                        
                      } else {
                        this.$message.error(res.msg);
                      }
                    })
                  } else {
                    this.$message.error(res.msg);
                  }
                  this.dialogFormVisible = false;
                })
              } else {
                this.qa.editMenu(this.form).then((res) => {
                  if(res.respCode == "0000") {
                    this.$message({
                        message: '菜单修改成功！',
                        type: 'success'
                    });
                    if(this.form.id == this.chooseForm.id) {
                      this.chooseForm = this.form;
                    }
                    // this.qryMenu();
                    this.qa.queryPageMenu({moduleParentCode: this.chooseForm.moduleCode, pageNo: 1, pageSize: 100}).then((res) => {
                      if(res.respCode == "0000") {
                        this.tableData = res.data;
                        
                      } else {
                        this.$message.error(res.msg);
                      }
                    })
                  } else {
                    this.$message.error(res.msg);
                  }
                  this.dialogFormVisible = false;
                })
              }
            } else {
              return false;
            }
        })
        
        
      },

      //选中左侧树
      chooseNode(data){
        // this.$set("")
        this.chooseForm = data;
        this.isShow = true;
        this.qa.queryPageMenu({moduleParentCode: data.moduleCode, pageNo: 1, pageSize: 100}).then((res) => {
          if(res.respCode == "0000") {
            this.tableData = res.data;
            
          } else {
            this.$message.error(res.msg);
          }
        })
      },

      //修改菜单
      editMenu(row){
        this.form = {
            moduleParentName: row.moduleParentName,
            moduleParentCode: row.moduleParentCode,
            moduleName: row.moduleName,
            moduleUrl: row.moduleUrl,
            moduleSort: row.moduleSort,
            moduleIcon: row.moduleIcon,
            moduleStatus: row.moduleStatus,
            moduleType: 2,
            id: row.id
        };
        this.dialogFormVisible = true;
        this.dialogTitle = "修改菜单";

      },
      //删除菜单
      delMenu(row){
          this.qa.delMenu({id: row.id}).then(res => {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.qryMenu();
            this.chooseNode(this.chooseForm);
          })

      },
      delMenus(row){
          this.qa.delMenu({id: row.id}).then(res => {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.isShow = false;
            this.qryMenu();
            // this.chooseNode(this.chooseForm);
          })

      },

      handleNodeClick(data){
        // console.log(data)
        this.form.moduleParentName = data.moduleName;
        this.form.moduleParentCode = data.moduleCode;

      },

      changeStatus(row){
        this.qa.doSwitchModuleStatus({id: row.id}).then(res => {
          if(res.respCode == "0000") {
            this.qa.queryPageMenu({moduleParentCode: this.chooseForm.moduleCode, pageNo: 1, pageSize: 100}).then((res) => {
              if(res.respCode == "0000") {
                this.tableData = res.data;
                
              } else {
                this.$message.error(res.msg);
              }
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        // this.qa.userSwitch({userId: row.userId}).then(res => {
        //   this.qa.queryUser({manageOrgId: this.orgid, pageNo: this.pageInfo.pageNo, pageSize: this.pageInfo.pageSize}).then((res) => {
        //     if(res.respCode == "0000") {
        //       this.tableData = res.data;
        //       this.pageInfo.total = res.total;
        //     } else {
        //       this.$message.error(res.msg);
        //     }
        //   })
        // })
      },

      filterNode(value, data) {
        if (!value) return true;
        return data.moduleName.indexOf(value) !== -1;
      },
      chooseIcon(item){
        this.form.moduleIcon = item;
      }
    },

}
</script>

<style scoped>
  .menu >>> .el-input .el-input__suffix .el-input__icon{
    line-height: 32px;
  }
  .menu_title {
    width: 100%;
    height: 68px;
    border: 1px solid #0091FF;
    background: rgba(25, 145, 252, 0.1);
    padding: 12px 20px;
    display: flex;
    box-sizing: border-box;
  }
  .descrip {
      font-size: 14px;
      color: #1991FC;
      flex-shrink: 0;
      width: 100px;
  }
  .descrip_content {
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
      line-height: 14px;
  }
  .descrip_content >p {
      margin-bottom: 12px;
  }
  .menu_content {
      width: 100%;
      background: #fff;
      margin-top: 12px;
  }
  .menu_content_title {
      height: 50px;
      border-bottom: 1px solid #EFEFEF;
      position: relative;

  }
  .menu_content_title >h4 {
    position: absolute;
    width: 54px;
    height: 36px;
    background: rgba(25, 145, 252, 0.1);
    color: #1991FC;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 36px;
    border-bottom: 2px solid #1991FC;
    left: 24px;
    bottom: 0;
  }
  .menu_content_content {
      display: flex;
  }
  .menu_left {
      padding: 10px 24px;
      width: 260px;
      border-right: 1px solid #EFEFEF;
  }
  .menu_left >>> .el-input__inner {
      height: 32px;
  }
  .menu_right {
      flex: 1;
      flex-shrink: 0;
      padding: 10px 24px;
  }
  .menu_right >h6 {
      font-size: 16px;
      color: #1D1E1F;
      margin-top: 30px;
  }
  .menu_right >h6 a {
      font-size: 14px;
      color: #1991FC;
      font-weight: 400;
      margin-left: 24px;
      cursor: pointer;
  }
  .menu_right >p {
      margin-top: 10px;
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
  }
  .menu_right >p >span {
      margin-right: 20px;
  }
</style>